@font-face {
    font-family: 'Euronics';
    src: url('../../../fonts/euronics-typeface/Euronics-Black.woff2') format('woff2'),
        url('../../../fonts/euronics-typeface/Euronics-Black.woff') format('woff'),
        url('../../../fonts/euronics-typeface/Euronics-Black.ttf') format('truetype');
    font-weight: bolder;
    font-style: normal;
}

@font-face {
    font-family: 'Euronics';
    src: url('../../../fonts/euronics-typeface/EuronicsRegular.woff2') format('woff2'),
        url('../../../fonts/euronics-typeface/EuronicsRegular.woff') format('woff'),
        url('../../../fonts/euronics-typeface/EuronicsRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Euronics';
    src: url('../../../fonts/euronics-typeface/EuronicsHeavy.woff2') format('woff2'),
        url('../../../fonts/euronics-typeface/EuronicsHeavy.woff') format('woff'),
        url('../../../fonts/euronics-typeface/EuronicsHeavy.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}